import { stringMap } from "./type";

export const zh: stringMap = {
  APP: '平台',
  YEAR: '数据年份',
  METRICS: '里程单位',
  CURRENCY: '货币',
  COUNTRY: '国家/地区',
  CITY: '城市',
  NOTES: '注释',
  DISTANCE: '里程',
  FARE: '费用',
  SUBMIT: '提交',
  OVERVIEW: '打车费用排名',
  SEARCH: '费用查询',
  SHARE: '分享数据',
  FARECNY: '费用（人民币）',
  DATAAGE: '平均样本年龄',
  MILE: '英里',
  KM: '公里',
  SELECTTEXT: '请选择城市。',
  OVERVIEWNOTE1: '* 汇率更新时间为2024年6月30日。',
  OVERVIEWNOTE2: '* 平均样本年龄指数据采样时间到今年过了多少年。值为0意味着数据都是今年采样的。',
  SHARENOTE1: '* 如果出发地和目的地不同，请选择人口数量更大的城市。行程的具体费用和里程通常可以在app里的行程单或者收据上找到。',
  THANKYOU: '谢谢！',
  CLOSE: '关闭',
  MOREDETAILS: '展开',
  COLLAPSE: '收起',
  P10: '10百分位',
  P50: '中位数',
  P90: '90百分位',
  MEDIANPPSF: '每平方尺价中位数',
  HOMESSOLD: '成交数量',
  STATE: '（行政）州',
  MEDIANSALEPRICE: '成交价中位数',
  MEDIANLISTPRICE: '挂牌价中位数',
  OVERVIEWHOUSING: '美国房价市场概览',
  OVERVIEWHOUSINGNOTE: '数据来自Redfin，筛选出月成交量超过100套的城市和月份。',
  PROPERTYTYPE: '住宅类型',
  REFILTER: '重新筛选',
  SEARCHHOUSING: '按城市搜索',
};

export const en: stringMap = {
  APP: 'App',
  YEAR: 'Year',
  METRICS: 'Metrics',
  CURRENCY: 'Currency',
  COUNTRY: 'Country/Region',
  CITY: 'City',
  NOTES: 'Notes',
  DISTANCE: 'Distance',
  FARE: 'Fare',
  SUBMIT: 'Submit',
  OVERVIEW: 'Taxi Fare Rankings',
  SEARCH: 'Search by City',
  SHARE: 'Share Your Ride',
  FARECNY: 'Fare in CNY',
  DATAAGE: 'Avg Sample Age',
  MILE: 'mile',
  KM: 'km',
  SELECTTEXT: 'Please select a city first.',
  OVERVIEWNOTE1: '* The latest update of the exchange rate occured on 06/30/2024.',
  OVERVIEWNOTE2: '* Avg Sample Age refers to the average age of the samples collected. 0 means all the samples are collected this year.',
  SHARENOTE1: '* Please pick the city with larger population if origin is different from the destination. The exact distance and fare of a ride can usually be found in the receipt / itinerary of your app.',
  THANKYOU: 'Thank you!',
  CLOSE: 'Close',
  MOREDETAILS: 'Expand',
  COLLAPSE: 'Collapse',
  P10: '10 Percentile',
  P50: 'Median',
  P90: '90 Percentile',
  MEDIANPPSF: 'Median Price per Square Feet',
  HOMESSOLD: 'Homes Sold',
  STATE: 'State',
  MEDIANSALEPRICE: 'Median Sale Price',
  MEDIANLISTPRICE: 'Median List Price',
  OVERVIEWHOUSING: 'US Housing Market Overview',
  OVERVIEWHOUSINGNOTE: 'Data provided by Redfin, a national real estate brokerage. Cities and months with >= 100 homes sold are in the list.',
  PROPERTYTYPE: 'Property Type',
  REFILTER: 'Refilter',
  SEARCHHOUSING: 'Search by City',
};

export class LangConfiguration {
  langMap: stringMap;
  state: string;

  constructor(lang: string) {
    if (lang == 'zh') {
      this.langMap = zh;
      this.state = 'zh';
    } else {
      this.langMap = en;
      this.state = 'en';
    }
  }

  get(key: string): string {
    return this.langMap[key];
  }
};
  