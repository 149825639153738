import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Select, FormControl, InputLabel, Typography, Paper, SelectChangeEvent, Box } from '@mui/material';
import currencySymbols from '../utils/symbols';
import FareTable from './FareTable';
import { LangConfiguration } from '../utils/language';
import { housingGeo, housingOverview } from '../utils/type';
import HousingLineChart from './HousingLineChart';

interface HousingSelectorProps {
  lang: LangConfiguration;
}

const HousingSelector: React.FC<HousingSelectorProps> = ({ lang }) => {

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  useEffect(() => {
    const fetchGeos = async () => {
      fetch(`${apiDomain}/housing/geo`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors', // Add this option
      })
      .then(response => response.json())
      .then((data: housingGeo[]) => {
        if (data) {
          const geoMap = new Map<string, string[]>();
          data.forEach(d => {
            geoMap.set(d.State, d.Cities);
          });
          setGeos(geoMap);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }

    fetchGeos();
    getPrice(selectedCity, selectedPropertyType);
  }, [])

  const [geos, setGeos] = useState<Map<string, string[]>>(new Map());
  const [selectedState, setSelectedState] = useState<string>('Washington');
  const [selectedCity, setSelectedCity] = useState<string>('Seattle');
  const [pricing, setPricing] = useState<housingOverview[]>([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState<string>('All Residential');

  const propertyTypes: string[] = [
    'All Residential', 'Condo/Co-op', 'Single Family Residential', 'Townhouse', 'Multi-Family (2-4 Unit)'];

  const states = Array.from(geos.keys()).sort((a, b) => a.localeCompare(b));
  const cities = geos.get(selectedState) || [];
  const sortedCities = cities.sort((a, b) => a.localeCompare(b));

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    const state: string = event.target.value as string;
    setSelectedState(state);
    setSelectedCity(''); // Reset city selection when state changes
  }
  
  const handleCityChange = (event: SelectChangeEvent<string>) => {
    const city = event.target.value as string;
    setSelectedCity(city);
    getPrice(city, selectedPropertyType);
  }

  const handlePropertyTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedPropertyType(event.target.value);
    getPrice(selectedCity, event.target.value);
  };

  const getPrice = (city: string, propertyType: string) => {
    const url = new URL(`${apiDomain}/housing/select`);
    setSelectedCity(city);
    setSelectedPropertyType(propertyType);
    // Append query parameters to the URL
    url.searchParams.append("state", selectedState);
    url.searchParams.append("city", city);
    url.searchParams.append('propertyType', propertyType);
    url.searchParams.append('sortBy', 'year');
    fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors', // Add this option
    })
    .then(response => response.json())
    .then((data: housingOverview[]) => {
      if (data) {
        console.log(data[0])
        const appendedData = data.map(item => ({
          ...item,
          Displayppsf: currencySymbols.USD + item.Medianppsf.toFixed(2),
          DisplaySalePrice: currencySymbols.USD + item.MedianSalePrice.toFixed(1),
          DisplayListPrice: currencySymbols.USD + item.MedianListPrice.toFixed(1),
        }));
        setPricing(appendedData);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const header = 
    [
        lang.get('STATE'), lang.get('CITY'), lang.get('YEAR'),
        lang.get('MEDIANPPSF'), lang.get('MEDIANSALEPRICE'), lang.get('MEDIANLISTPRICE'),
        lang.get('HOMESSOLD'), lang.get('PROPERTYTYPE')];
  const rowKeys = ['State', 'City', 'Year', 'Displayppsf', 'DisplaySalePrice', 'DisplayListPrice', 'HomesSold', 'PropertyType'];

  const pricingDisplay = pricing.length !== 0 ? (<Box sx={{ mt: 4 }}>
    <HousingLineChart lang={lang} data={[...pricing].reverse()}/>
      <FareTable
        header={header}
        rowKeys={rowKeys}
        rows={pricing}
        paginated={false}
      /></Box>) : (
    <Paper elevation={3} style={{ padding: '1rem', marginTop: '1rem' }}>
      <Typography variant="body1">{lang.get('SELECTTEXT')}</Typography>
    </Paper>
  );

  return (
    <section className="comparison-tool">
      <Typography variant="h4" component="h2" gutterBottom>
        {lang.get('SEARCHHOUSING')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{lang.get('STATE')}</InputLabel>
            <Select value={selectedState} onChange={handleStateChange} label="State">
              {states.map((state, index) => (
                <MenuItem key={index} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{lang.get('CITY')}</InputLabel>
              <Select
                value={selectedCity}
                onChange={handleCityChange}
                label="City"
                disabled={!selectedState} // Disable until a city is selected
              >
                {sortedCities
                  .map((city: string, index: number) => (
                    <MenuItem key={index} value={city}>
                      {city}
                    </MenuItem>
                ))}
              </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{lang.get('PROPERTYTYPE')}</InputLabel>
            <Select value={selectedPropertyType} onChange={handlePropertyTypeChange} label={lang.get('PROPERTYTYPE')}>
              {propertyTypes.map((propertyType, index) => (
                <MenuItem key={index} value={propertyType}>
                  {propertyType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {pricingDisplay}
    </section>
  );
};

export default HousingSelector;
