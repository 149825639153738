import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LangConfiguration } from '../utils/language';
import { housingOverview } from '../utils/type';

interface HousingLineChartProps {
  lang: LangConfiguration;
  data: housingOverview[];
}
  

const HousingLineChart: React.FC<HousingLineChartProps> = ({ lang, data }) => {
  return (
    <ResponsiveContainer width='100%' height={400}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='Year' />
        <YAxis yAxisId='left' domain={['auto', 'auto']}/>
        <YAxis yAxisId='right' domain={['auto', 'auto']} orientation='right' />
        <Tooltip />
        <Legend />
        <Line yAxisId='left' type='monotone' dataKey='Medianppsf' stroke='#8884d8' />
        <Line yAxisId='right' type='monotone' dataKey='MedianSalePrice' stroke='#008080' />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default HousingLineChart;
