import { stringMap } from "./type";

const currencySymbols: stringMap = {
  'AFN': '؋',
  'ANG': 'ƒ',
  'ARS': '$',
  'AUD': '$',
  'AWG': 'ƒ',
  'AZN': 'ман',
  'BAM': 'KM',
  'BBD': '$',
  'BGN': 'лв',
  'BMD': '$',
  'BND': '$',
  'BOB': '$b',
  'BRL': 'R$',
  'BSD': '$',
  'BWP': 'P',
  'BYR': 'p.',
  'BZD': 'BZ$',
  'CAD': '$',
  'CHF': 'CHF',
  'CLP': '$',
  'CNY': '¥',
  'COP': '$',
  'CRC': '₡',
  'CUP': '₱',
  'CZK': 'Kč',
  'DKK': 'kr',
  'DOP': 'RD$',
  'EGP': '£',
  'EUR': '€',
  'FJD': '$',
  'FKP': '£',
  'GBP': '£',
  'GIP': '£',
  'GTQ': 'Q',
  'GYD': '$',
  'HKD': '$',
  'HNL': 'L',
  'HRK': 'kn',
  'HUF': 'Ft',
  'IDR': 'Rp',
  'ILS': '₪',
  'IRR': '﷼',
  'ISK': 'kr',
  'JMD': 'J$',
  'JPY': '¥',
  'KGS': 'лв',
  'KHR': '៛',
  'KPW': '₩',
  'KRW': '₩',
  'KYD': '$',
  'KZT': 'лв',
  'LAK': '₭',
  'LBP': '£',
  'LKR': '₨',
  'LRD': '$',
  'LTL': 'Lt',
  'LVL': 'Ls',
  'MKD': 'ден',
  'MNT': '₮',
  'MUR': '₨',
  'MXN': '$',
  'MYR': 'RM',
  'MZN': 'MT',
  'NGN': '₦',
  'NIO': 'C$',
  'NOK': 'kr',
  'NPR': '₨',
  'NZD': '$',
  'OMR': '﷼',
  'PAB': 'B/.',
  'PEN': 'S/.',
  'PHP': 'Php',
  'PKR': '₨',
  'PLN': 'zł',
  'PYG': 'Gs',
  'QAR': '﷼',
  'RON': 'lei',
  'RSD': 'Дин.',
  'RUB': 'руб',
  'SAR': '﷼',
  'SBD': '$',
  'SCR': '₨',
  'SEK': 'kr',
  'SGD': '$',
  'SHP': '£',
  'SOS': 'S',
  'SRD': '$',
  'SVC': '$',
  'SYP': '£',
  'THB': '฿',
  'TRY': 'TL',
  'TTD': 'TT$',
  'TWD': 'NT$',
  'UAH': '₴',
  'USD': '$',
  'UYU': '$U',
  'UZS': 'лв',
  'VEF': 'Bs',
  'VND': '₫',
  'XCD': '$',
  'YER': '﷼',
  'ZAR': 'R',
}

export default currencySymbols;
