import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

interface FooterProps {
  enOnClick: (e: {}) => void,
  zhOnClick:(e: {}) => void,
}

const Footer: React.FC<FooterProps> = ({ enOnClick, zhOnClick }) => {
  return (
    <footer>
      <Container maxWidth="lg">
        <Typography variant="body1">
          <Link onClick={enOnClick}>English</Link> | <Link onClick={zhOnClick}>中文</Link> | <Link href="#">Contact</Link>
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          {'© '}
          {new Date().getFullYear()}
          {' Pricecat Tracker. All Rights Reserved.'}
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
