import React, { useEffect, useState } from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route,  Routes } from 'react-router-dom';
import Taxifare from './pages/taxifare';
import Home from './pages/home';
import Housing from './pages/housing';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#008080', // Teal color
    },
    secondary: {
      main: '#006666', // Darker teal for contrast
    },
  },
});

const App: React.FC = () => {

  const [tracked, setTracked] = useState<boolean>(false);

  useEffect(() => {

    const track = async () => {
      const apiDomain = process.env.REACT_APP_API_DOMAIN;
      fetch(`${apiDomain}/metrics/pageview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors', // Add this option
      })
      .then(response => response.json())
      .then(data => {
        if (!tracked) {
          setTracked(true);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }

    track()
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Housing />} />
          <Route path="/taxifare" element={<Taxifare />} />
          <Route path="/housing" element={<Housing />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
