import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Select, FormControl, InputLabel, Typography, Paper, SelectChangeEvent, IconButton, Collapse } from '@mui/material';
import currencySymbols from '../utils/symbols';
import FareTable from './FareTable';
import { LangConfiguration } from '../utils/language';
import { countryData, fareOverview } from '../utils/type';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

interface FareSelectorProps {
  lang: LangConfiguration;
  countryState: Map<string, countryData>;
}

const FareSelector: React.FC<FareSelectorProps> = ({ lang, countryState }) => {

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [fare, setFare] = useState<fareOverview[]>([]);
  const [fareDetail, setFareDetail] = useState<fareOverview[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const header = [
    lang.get('COUNTRY'), lang.get('CITY'), lang.get('FARECNY'),
    lang.get('FARE'), lang.get('CURRENCY'),
    lang.get('P10'), lang.get('P50'), lang.get('P90'),
  ];
  const rowKeys = [
    'Country', 'City', 'FareInCNY',
    'DisplayFare', 'Currency',
    'Percentile10', 'Percentile50', 'Percentile90',
  ];

  const detailHeader = [
    lang.get('COUNTRY'), lang.get('CITY'), lang.get('FARECNY'),
    lang.get('FARE'), lang.get('CURRENCY'),
    lang.get('YEAR'), lang.get('APP'), lang.get('P10'), lang.get('P50'), lang.get('P90'),
  ];
  const detailRowKeys = [
    'Country', 'City', 'FareInCNY', 'DisplayFare',
    'Currency', 'Year', 'Platform',
    'Percentile10', 'Percentile50', 'Percentile90',
  ];

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    const country: string = event.target.value as string;
    setSelectedCountry(country);
    setSelectedCity(''); // Reset city selection when country changes
  };

  const metricsText = (metrics: string) => {
    return metrics === 'miles' ? ` /${lang.get('MILE')}` : ` /${lang.get('KM')}`;
  }

  const processResponseData = (data: fareOverview[]) => {
    const appendedData = data.map(item => ({
      ...item,
      FareInCNY: currencySymbols['CNY'] + item.FarePerDistanceInCNY.toFixed(2) + ` /${lang.get('KM')}`,
      DisplayFare: currencySymbols[item.Currency] + item.FarePerDistance.toFixed(2) + metricsText(item.Metrics),
      Percentile10: currencySymbols['CNY'] + item.Percentiles[0].toFixed(2) + ` /${lang.get('KM')}`,
      Percentile50: currencySymbols['CNY'] + item.Percentiles[1].toFixed(2) + ` /${lang.get('KM')}`,
      Percentile90: currencySymbols['CNY'] + item.Percentiles[2].toFixed(2) + ` /${lang.get('KM')}`,
    }));
    return appendedData
  }

  const getCityOverview = (city: string) => {
    const url = new URL(`${apiDomain}/fare/select`);
    setSelectedCity(city);
    // Append query parameters to the URL
    url.searchParams.append("country", countryState.get(selectedCountry)?.country_name_en as string);
    url.searchParams.append("city", countryState.get(selectedCountry)?.cities[city] as string);
    fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors', // Add this option
    })
    .then(response => response.json())
    .then((data: fareOverview[]) => {
      if (data) {
        const appendedData = processResponseData(data);
        setFare(appendedData);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const getCityDetail = (city: string) => {
    const url = new URL(`${apiDomain}/fare/select/detail`);
    setSelectedCity(city);
    // Append query parameters to the URL
    url.searchParams.append("country", countryState.get(selectedCountry)?.country_name_en as string);
    url.searchParams.append("city", countryState.get(selectedCountry)?.cities[city] as string);
    fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors', // Add this option
    })
    .then(response => response.json())
    .then((data: fareOverview[]) => {
      if (data) {
        const appendedData = processResponseData(data);
        setFareDetail(appendedData);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }
  
  const handleCityChange = (event: SelectChangeEvent<string>) => {
    const city = event.target.value as string;
    getCityOverview(city);
    getCityDetail(city);
  }

  const countries = Array.from(countryState.keys()).sort((a, b) => a.localeCompare(b));

  const cityMap = countryState.get(selectedCountry)?.cities;
  let cities: string[] = [];
  if (cityMap && Object.keys(cityMap).length > 0) {
    cities = Object.keys(cityMap).sort((a, b) => a.localeCompare(b));
  }

  const iconText = open ? (
    <Typography variant="body2">{lang.get('COLLAPSE')}</Typography>) : (
    <Typography variant="body2">{lang.get('MOREDETAILS')}</Typography>);

  const detailIcon = fare.length > 0 ? (<Typography variant="body2">{lang.get('MOREDETAILS')}
    <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpen(!open)}
      >
      {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </IconButton></Typography>) : null;

  const fareDisplay = fare.length !== 0 ? (<FareTable
      header={header}
      rowKeys={rowKeys}
      rows={fare}
      paginated={false}
    />) : (
    <Paper elevation={3} style={{ padding: '1rem', marginTop: '1rem' }}>
      <Typography variant="body1">{lang.get('SELECTTEXT')}</Typography>
    </Paper>
  )

  const fareDetailDisplay = fareDetail.length !== 0 ? (<FareTable
    header={detailHeader}
    rowKeys={detailRowKeys}
    rows={fareDetail}
  />) : null

  return (
    <section className="comparison-tool">
      <Typography variant="h4" component="h2" gutterBottom>
        {lang.get('SEARCH')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{lang.get('COUNTRY')}</InputLabel>
            <Select value={selectedCountry} onChange={handleCountryChange} label="Country/Region">
              {countries.map((country, index) => (
                <MenuItem key={index} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{lang.get('CITY')}</InputLabel>
              <Select
                value={selectedCity}
                onChange={handleCityChange}
                label="City"
                disabled={!selectedCountry} // Disable until a city is selected
              >
                {cities
                  .map((city: string, index: number) => (
                    <MenuItem key={index} value={city}>
                      {city}
                    </MenuItem>
                ))}
              </Select>
          </FormControl>
        </Grid>
      </Grid>
      {fareDisplay}
      {detailIcon}
      <Collapse in={open} timeout="auto" unmountOnExit>
        {fareDetailDisplay}
      </Collapse>
    </section>
  );
};

export default FareSelector;
