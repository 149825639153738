import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FareTable from './FareTable';
import { LangConfiguration } from '../utils/language';
import { housingOverview } from '../utils/type';
import currencySymbols from '../utils/symbols';

interface HousingOverviewProps {
  lang: LangConfiguration;
}

const HousingOverview: React.FC<HousingOverviewProps> = ({ lang }) => {

  const years: string[] = ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015'];
  const propertyTypes: string[] = [
    'All Residential', 'Condo/Co-op', 'Single Family Residential', 'Townhouse', 'Multi-Family (2-4 Unit)'];
  
  const [overview, setOverview] = useState<housingOverview[]>([]);
  const [header, setHeader] = useState<string[]>([]);
  const [rowKeys, setRowKeys] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>((new Date()).getFullYear().toString());
  const [selectedPropertyType, setSelectedPropertyType] = useState<string>('All Residential');

  const topK = 40;

  const fetchData = () => {
    const apiDomain = process.env.REACT_APP_API_DOMAIN;
      const url = new URL(`${apiDomain}/housing/top`);
      url.searchParams.append('year', selectedYear.toString());
      url.searchParams.append('propertyType', selectedPropertyType.toString());
      url.searchParams.append('topK', topK.toString());
      url.searchParams.append('sortBy', 'medianppsf');

      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors', // Add this option
      })
      .then(response => response.json())
      .then((data: housingOverview[]) => {
        if (data) {
          console.log(data[0])
          const appendedData = data.map(item => ({
            ...item,
            Displayppsf: currencySymbols.USD + item.Medianppsf.toFixed(2),
            DisplaySalePrice: currencySymbols.USD + item.MedianSalePrice.toFixed(1),
            DisplayListPrice: currencySymbols.USD + item.MedianListPrice.toFixed(1),
          }));
          setOverview(appendedData);
          setHeader(
            [
                lang.get('STATE'), lang.get('CITY'), lang.get('YEAR'),
                lang.get('MEDIANPPSF'), lang.get('MEDIANSALEPRICE'), lang.get('MEDIANLISTPRICE'),
                lang.get('HOMESSOLD'), lang.get('PROPERTYTYPE')]);
          setRowKeys(['State', 'City', 'Year', 'Displayppsf', 'DisplaySalePrice', 'DisplayListPrice', 'HomesSold', 'PropertyType']);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    const fetchOverview = async () => {
        fetchData();
    }

    fetchOverview();
  }, [])

  const handleYearChange = (event: SelectChangeEvent<string>) => {
    setSelectedYear(event.target.value);
  };

  const handlePropertyTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedPropertyType(event.target.value);
  };

  const handleSubmit = () => {
    fetchData();
  };

  return (
    <section className="price-overview">
      <Typography variant="h4" component="h2" gutterBottom>
        {lang.get('OVERVIEWHOUSING')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{lang.get('YEAR')}</InputLabel>
            <Select value={selectedYear} onChange={handleYearChange} label={lang.get('YEAR')}>
              {years.map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{lang.get('PROPERTYTYPE')}</InputLabel>
            <Select value={selectedPropertyType} onChange={handlePropertyTypeChange} label={lang.get('PROPERTYTYPE')}>
              {propertyTypes.map((propertyType, index) => (
                <MenuItem key={index} value={propertyType}>
                  {propertyType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
            <Button type="button" variant="text" color="primary" onClick={handleSubmit} sx={{
            width: '95%',
            height: '100%',
        }}>
            {lang.get('REFILTER')}
            </Button>
        </Grid>
      </Grid>
      <FareTable
        header={header}
        rowKeys={rowKeys}
        rows={overview}
      />
      <div style={{ marginTop: '20px', textAlign: 'left' }}>
        <Typography variant="body2" color="textSecondary">
          {lang.get('OVERVIEWHOUSINGNOTE')}
        </Typography>
      </div>
    </section>
  );
};

export default HousingOverview;
