import React, { useState } from 'react';
import { TextField, Button, Typography, Grid, Paper, FormControl, InputLabel, Select, MenuItem, CircularProgress, SelectChangeEvent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText } from '@mui/material';
import { LangConfiguration } from '../utils/language';
import { countryData } from '../utils/type';

const metrics: string[] = ['kilometers', 'miles'];
const apps: string[] = ['didi', 'uber', 'lyft', 'grab', 'go', 'others'];
const years: string[] = ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015'];

interface UserContributionsProps {
  lang: LangConfiguration;
  countryState: Map<string, countryData>;
  loading: boolean;
}

const UserContributions: React.FC<UserContributionsProps> = ({ lang, countryState, loading }) => {
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [selectedMetrics, setSelectedMetrics] = useState<string>('kilometers');
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');
  const [selectedApp, setSelectedApp] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string>('2024');
  const [displayText, setDisplayText] = useState<string>('');
  const [fare, setFare] = useState<string>('');
  const [distance, setDistance] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [open, setOpen] = useState(false);

  const [fareError, setFareError] = useState('');
  const [distanceError, setDistanceError] = useState('');
  const [currencyError, setCurrencyError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [cityError, setCityError] = useState('');
  const [appError, setAppError] = useState('');

  const countries = Array.from(countryState.keys()).sort((a, b) => a.localeCompare(b));
  const currency = Array.from(countryState.values()).map(v => v.currency_code).sort((a, b) => a.localeCompare(b));

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    const country: string = event.target.value as string;
    setSelectedCountry(country);
    setSelectedCity(''); // Reset city selection when country changes
    setSelectedCurrency(countryState.get(country)?.currency_code as string);
    if (countryState.get(country)?.country_code == 'USA') {
      setSelectedMetrics('miles');
    } else {
      setSelectedMetrics('kilometers');
    }  
  };

  const handleCityChange = (event: SelectChangeEvent<string>) => {
    setSelectedCity(event.target.value as string);
  };

  const handleFareChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFare(event.target.value);
  };

  const handleDistanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDistance(event.target.value);
  };

  const handleMetricsChange = (event: SelectChangeEvent<string>) => {
    setSelectedMetrics(event.target.value);
  };

  const handleCurrencyChange = (event: SelectChangeEvent<string>) => {
    setSelectedCurrency(event.target.value);
  };

  const handleAppChange = (event: SelectChangeEvent<string>) => {
    setSelectedApp(event.target.value);
  };

  const handleYearChange = (event: SelectChangeEvent<string>) => {
    setSelectedYear(event.target.value);
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isNumeric = (value: string): boolean => {
    return !isNaN(Number(value)) && value.trim() !== '';
  };

  const isEmpty = (value: string): boolean => {
    return value.trim() !== '';
  };

  const handleSubmit = () => {
    if (!isNumeric(fare)) {
      setFareError('Please enter a valid number.');
      return;
    } else {
      setFareError('');
    }

    if (!isNumeric(distance)) {
      setDistanceError('Please enter a valid number.');
      return;
    } else {
      setDistanceError('');
    }

    if (!isEmpty(selectedCountry)) {
      setCountryError('Please select a country.');
      return;
    } else {
      setCountryError('');
    }

    if (!isEmpty(selectedCity)) {
      setCityError('Please select a city.');
      return;
    } else {
      setCityError('');
    }

    if (!isEmpty(selectedApp)) {
      setAppError('Please select a value.');
      return;
    } else {
      setAppError('');
    }

    if (!isEmpty(selectedCurrency)) {
      setCurrencyError('Please select a currency.');
      return;
    } else {
      setCurrencyError('');
    }

    const contribution = {
      country: countryState.get(selectedCountry)?.country_name_en,
      city: countryState.get(selectedCountry)?.cities[selectedCity],
      iso3: countryState.get(selectedCountry)?.country_code,
      fare: parseFloat(fare),
      distance: parseFloat(distance),
      currency: selectedCurrency,
      metrics: selectedMetrics,
      app: selectedApp,
      year: parseInt(selectedYear),
      notes: notes,
    };
    const apiDomain = process.env.REACT_APP_API_DOMAIN;
    fetch(`${apiDomain}/fare/submit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contribution),
      mode: 'cors', // Add this option
    })
    .then(response => {
      if (!response.ok) {
        // Check if the response is not OK (status in the range 200-299)
        if (response.status === 400) {
          throw new Error('Bad Request');
        } else {
          throw new Error('An error occurred');
        }
      }
      return response.json()
    })
    .then(data => {
      console.log('Response from server:', data);
      // Close the modal on successful submission
      setFare('');
      setDistance('');
      setOpen(true);
      setDisplayText(lang.get('THANKYOU'));
    })
    .catch(error => {
      console.error('Error:', error);
      setDisplayText('Bad Request');
      setOpen(true);
    })
  };

  if (loading) {
    return <CircularProgress />;
  }

  const cityMap = countryState.get(selectedCountry)?.cities;
  let cities: string[] = [];
  if (cityMap && Object.keys(cityMap).length > 0) {
    cities = Object.keys(cityMap).sort((a, b) => a.localeCompare(b));
  }

  return (
    <section className="user-contributions">
      <Typography variant="h4" component="h2" gutterBottom>
        {lang.get('SHARE')}
      </Typography>
      <Paper elevation={3} style={{ padding: '1rem' }}>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" error={!!countryError}>
                <InputLabel>{lang.get('COUNTRY')}</InputLabel>
                <Select value={selectedCountry} onChange={handleCountryChange} label="Country/Region">
                  {countries.map((country, index) => (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
                {countryError && <FormHelperText>{countryError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" error={!!cityError}>
                <InputLabel>{lang.get('CITY')}</InputLabel>
                <Select
                  value={selectedCity}
                  onChange={handleCityChange}
                  label="City"
                  disabled={!selectedCountry} // Disable until a city is selected
                >
                  {cities
                  .map((city: string, index: number) => (
                    <MenuItem key={index} value={city}>
                      {city}
                    </MenuItem>
                  ))}
                </Select>
                {cityError && <FormHelperText>{cityError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                value={fare}
                onChange={handleFareChange}
                error={!!fareError}
                helperText={fareError}
                label={lang.get('FARE')}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" error={!!currencyError}>
                <InputLabel>{lang.get('CURRENCY')}</InputLabel>
                <Select
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                  label="Currency"
                >
                  {currency.map((c, index) => (
                    <MenuItem key={index} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </Select>
                {currencyError && <FormHelperText>{currencyError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                value={distance}
                onChange={handleDistanceChange}
                error={!!distanceError}
                helperText={distanceError}
                label={lang.get('DISTANCE')}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>{lang.get('METRICS')}</InputLabel>
                <Select
                  value={selectedMetrics}
                  onChange={handleMetricsChange}
                  label="Metrics"
                >
                  {metrics.map((metric, index) => (
                    <MenuItem key={index} value={metric}>
                      {metric}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" error={!!appError}>
                <InputLabel>{lang.get('APP')}</InputLabel>
                <Select
                  value={selectedApp}
                  onChange={handleAppChange}
                  label="App"
                >
                  {apps.map((app, index) => (
                    <MenuItem key={index} value={app}>
                      {app}
                    </MenuItem>
                  ))}
                </Select>
                {appError && <FormHelperText>{appError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>{lang.get('YEAR')}</InputLabel>
                <Select
                  value={selectedYear}
                  onChange={handleYearChange}
                  label="Year"
                >
                  {years.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label={lang.get('NOTES')} variant="outlined" multiline rows={3}
                onChange={handleNotesChange}/>
            </Grid>
            <Grid item xs={12}>
              <Button type="button" variant="contained" color="primary" onClick={handleSubmit}>
              {lang.get('SUBMIT')}
              </Button>
              <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                  <DialogContentText>
                    {displayText}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    {lang.get('CLOSE')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </form>
      </Paper>
      {/* Footnotes */}
      <div style={{ marginTop: '20px', textAlign: 'left' }}>
        <Typography variant="body2" color="textSecondary">
          {lang.get('SHARENOTE1')}
        </Typography>
      </div>
    </section>
  );
};

export default UserContributions;
