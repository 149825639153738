import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import currencySymbols from '../utils/symbols';
import FareTable from './FareTable';
import { LangConfiguration } from '../utils/language';
import { fareOverview } from '../utils/type';

interface FareOverviewProps {
  lang: LangConfiguration;
}

const FareOverview: React.FC<FareOverviewProps> = ({ lang }) => {
  
  const [overview, setOverview] = useState<fareOverview[]>([]);
  const [header, setHeader] = useState<string[]>([]);
  const [rowKeys, setRowKeys] = useState<string[]>([]);

  const currentYear = (new Date()).getFullYear();

  useEffect(() => {
    const metricsText = (metrics: string) => {
      return metrics === 'miles' ? ` /${lang.get('MILE')}` : ` /${lang.get('KM')}`;
    }

    const fetchOverview = async () => {
      const apiDomain = process.env.REACT_APP_API_DOMAIN;
      fetch(`${apiDomain}/fare/overview`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors', // Add this option
      })
      .then(response => response.json())
      .then((data: fareOverview[]) => {
        if (data) {
          console.log(data[0])
          const appendedData = data.map(item => ({
            ...item,
            FareInCNY: currencySymbols['CNY'] + item.FarePerDistanceInCNY.toFixed(2) + ` /${lang.get('KM')}`,
            DisplayFare: currencySymbols[item.Currency] + item.FarePerDistance.toFixed(2) + metricsText(item.Metrics),
            DataAge: (currentYear - item.AvgYear!).toFixed(1),
          }));
          setOverview(appendedData);
          setHeader([lang.get('COUNTRY'), lang.get('CITY'), lang.get('FARECNY'),  lang.get('FARE'), lang.get('CURRENCY'), lang.get('DATAAGE')]);
          setRowKeys(['Country', 'City', 'FareInCNY', 'DisplayFare', 'Currency', 'DataAge']);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }

    fetchOverview();
  }, [])

  return (
    <section className="price-overview">
      <Typography variant="h4" component="h2" gutterBottom>
        {lang.get('OVERVIEW')}
      </Typography>
      <FareTable
        header={header}
        rowKeys={rowKeys}
        rows={overview}
      />
      <div style={{ marginTop: '20px', textAlign: 'left' }}>
        <Typography variant="body2" color="textSecondary">
          {lang.get('OVERVIEWNOTE1')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {lang.get('OVERVIEWNOTE2')}
        </Typography>
      </div>
    </section>
  );
};

export default FareOverview;
