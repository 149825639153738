import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Table, Pagination } from "@mui/material";
import { useState } from "react";

interface FareTableProps {
  header: string[];
  rowKeys: string[];
  rows: Record<string, any>[];
  paginated?: boolean;
}

const FareTable: React.FC<FareTableProps> = ({ header, rowKeys, rows, paginated = true }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage: number = 10; // Number of items per page

    // Calculate the number of pages
    const pageCount: number = Math.ceil(rows.length / itemsPerPage);

    // Calculate the items to display on the current page
    const displayedItems = rows.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // Handle page change
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setCurrentPage(value);
    };

    const fareTable = (<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
          {header.map((item, index) => (
            <TableCell key={index}>{item}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedItems.map((item, index) => (
            <TableRow key={index}>
              {rowKeys.map((rowKey, i) => (
                <TableCell key={i+'a'}>{item[rowKey]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    )

    const pagination = paginated ? <Pagination
      count={pageCount}
      page={currentPage}
      onChange={handlePageChange}
      color="primary"
      showFirstButton
      showLastButton
      style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
    /> : null;
  
    return <div>
      {fareTable}
      {pagination}
    </div>;
  };
  
  export default FareTable;
  