import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material';


const NavBar = styled(AppBar)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  backgroundColor: theme.palette.common.white, // Set background color to white
  color: theme.palette.primary.main, // Set text color to primary color
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)', // Reduced shadow
}));


const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <NavBar position="static">
      <Toolbar>
        <Typography variant="h5">
          Pricecat
        </Typography>
      </Toolbar>
    </NavBar>
  );
};

export default Header;
