import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FareOverview from '../components/FareOverview';
import UserContributions from '../components/UserContributions';
import FareSelector from '../components/FareSelector';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import '../App.css';
import {LangConfiguration} from '../utils/language'
import Papa from 'papaparse';
import { cityData, countryData, countryMetadata, stringMap } from '../utils/type';

const Taxifare: React.FC = () => {

  const [lang, setLang] = useState<LangConfiguration>(new LangConfiguration('zh'));
  const [countryState, setCountryState] = useState<Map<string, countryData>>(new Map<string, countryData>());
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const handleCsvResponse = async(response: Response) => {
      const result = await response.text();
      return result;
    };

    const parseCountries = async () => {
      try {
        const data = await fetch('/pworldcities.csv');
        const csvData = await handleCsvResponse(data);
        
        Papa.parse(csvData, {
          header: true,
          complete: (result: { data: cityData[]; }) => {
            const appearedCountries: Map<string, countryData> = new Map();
            const unsortedCountries: Set<countryMetadata> = new Set();
            result.data.forEach((({ country, country_zh, iso3, city, city_zh, currency_name, currency_code }) => {
              const country_key = country_zh
              if (!country_key) {
                return;
              }
              if (!appearedCountries.has(country_key)) {
                const countryValue: countryMetadata = {
                  country_name: country_key,
                  country_name_zh: country_zh,
                  country_name_en: country,
                  country_code: iso3,
                  currency_name,
                  currency_code,
                }
                unsortedCountries.add(countryValue);
                const cities: stringMap = {};
                cities[city_zh] = city;
                const cities_zh: stringMap = {};
                cities_zh[city_zh] = city;
                const cities_en: stringMap = {};
                cities_en[city] = city;
                appearedCountries.set(
                  country_key,
                  {
                    ...countryValue,
                    cities: cities,
                    cities_zh: cities_zh,
                    cities_en: cities_en,
                  });
              } else {
                const citiesMap = appearedCountries.get(country_key)?.cities;
                if (citiesMap) {
                  citiesMap[city_zh] = city;
                }
                const citiesMapEn = appearedCountries.get(country_key)?.cities_en;
                if (citiesMapEn) {
                  citiesMapEn[city] = city;
                }
                const citiesMapZh = appearedCountries.get(country_key)?.cities_zh;
                if (citiesMapZh) {
                  citiesMapZh[city_zh] = city;
                }
              }
            }));
            
            setCountryState(appearedCountries);
            setLoading(false);
          },
        });
      } catch (error) {
        console.error('Error fetching the CSV file:', error);
      }
    }
    parseCountries();
  }, []);

  return (
    <div className="App">
    <CssBaseline />
    <Header />
    <Container component="main">
        <FareOverview lang={lang}/>
        <FareSelector lang={lang} countryState={countryState}/>
        <UserContributions lang={lang} countryState={countryState} loading={loading}/>
    </Container>
    <Footer
        enOnClick={(e) => {
        if (lang.state == 'en') {
            return;
        }
        setLang(new LangConfiguration('en'));
        const newCountryState = new Map<string, countryData>();
        countryState.forEach((value, key) => {
            value.cities = value.cities_en;
            newCountryState.set(value.country_name_en, value);
        });
        setCountryState(newCountryState);
        }}
        zhOnClick={(e) => {
        if (lang.state == 'zh') {
            return;
        }
        setLang(new LangConfiguration('zh'));
        const newCountryState = new Map<string, countryData>();
        countryState.forEach((value, key) => {
            value.cities = value.cities_zh;
            newCountryState.set(value.country_name_zh, value);
        });
        setCountryState(newCountryState);
        }}/>
    </div>
  );
};

export default Taxifare;
