import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import '../App.css';
import {LangConfiguration} from '../utils/language'
import HousingOverview from '../components/HousingOverview';
import HousingSelector from '../components/HousingSelector';

const Housing: React.FC = () => {

  const [lang, setLang] = useState<LangConfiguration>(new LangConfiguration('zh'));

  return (
    <div className="App">
    <CssBaseline />
    <Header />
    <Container component="main">
        <HousingOverview lang={lang}/>
        <HousingSelector lang={lang}/>
    </Container>
    <Footer
        enOnClick={(e) => {
        if (lang.state == 'en') {
            return;
        }
        setLang(new LangConfiguration('en'));
        }}
        zhOnClick={(e) => {
        if (lang.state == 'zh') {
            return;
        }
        setLang(new LangConfiguration('zh'));
        }}/>
    </div>
  );
};

export default Housing;
